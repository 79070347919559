<template>
  <section class="report">
    <div class="pa-3">
      <h1 class="page-title">Отмененные бронирования</h1>
    </div>
    <reports-filter
      class="mb-5"
      v-model="filterData"
      @submitFilter="handleSubmitFilter"
      :xslData="xslData"
    />
    <v-data-table
      dense
      class="base-table"
      :headers="headers"
      :items="dataTable"
    />
  </section>
</template>

<script>
import ReportsFilter from "@/components/ReportsFilter.vue";
export default {
  name: "CancledBooking",
  components: { ReportsFilter },
  data: () => ({
    headers: [
      {
        text: "Дата",
        value: "DataTime",
      },
      {
        text: "Расположение",
        value: "location",
      },
      {
        text: "Наименование",
        value: "space_name",
      },
      {
        text: "Вместимость",
        value: "capacity",
      },
      {
        text: "Длительность",
        value: "duration",
      },
      {
        text: "Организатор",
        value: "organizer",
      },
    ],
    filterData: {
      location: null,
      space_name: null,
      capacity: null,
      interval: [
        `${new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)}`,
        `${new Date(
          Date.now() +
            24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)}`,
      ],
    },
  }),
  mounted() {
    this.$store.dispatch("fetchAllLocation");
    this.$store.commit("SET_REPORT_TABLE", []);
  },
  computed: {
    dataTable() {
      // REFACTORING THIS
      // get all reservation from spaces arrays
      let tmp = [
        ...this.$store.getters.getReportTable.filter(
          (item) => item.reservations.length > 0
        ),
      ];
      tmp = tmp.map((item) => {
        let tmp = item.reservations.map((res) => {
          res.space_name = item.space_name;
          res.capacity = item.capacity;
          return res;
        });
        return tmp;
      });
      console.log("tmp", tmp);
      let result = [];
      for (let i = 0; i < tmp.length; i++) {
        result = [...result, ...tmp[i]];
      }
      result = result.map((item) => {
        const getDuration = (start_h, start_m, end_h, end_m) => {
          let hours = end_h - start_h;
          let minutes = end_m - start_m;
          if (minutes < 0) {
            hours--;
            minutes = 60 + minutes;
          }
          return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}`;
        };
        let d = getDuration(
          Number(item.time_begin.slice(0, 2)),
          Number(item.time_begin.slice(3, 5)),
          Number(item.time_end.slice(0, 2)),
          Number(item.time_end.slice(3, 5))
        );
        console.log("item?", item);
        return {
          DataTime: item.reservation_date,
          location: this.filterData.location,
          space_name: item.space_name,
          capacity: item.capacity,
          duration: d,
          organizer: item.organizer,
        };
      });

      return result;
    },
    xslData() {
      return this.dataTable.map((item) => {
        return {
          Дата: item.DataTime,
          Расположение: item.location,
          Наименование: item.space_name,
          Вместимость: item.capacity,
          Длительность: item.duration,
          Организатор: item.organizer,
        };
      });
    },
  },
  methods: {
    handleSubmitFilter() {
      let payload = { ...this.filterData };
      payload.interval = {
        begin: payload.interval[0],
        end: payload.interval[1],
      };
      payload.space_name =
        payload.space_name === "" ? null : payload.space_name;
      this.$store.dispatch("fetchCancelledMeetings", payload);
      console.log("payload= , ", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
